import React, { useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import GeneralForm from '../components/Form/general';
import DetailsForm from '../components/Form/details';
import ProfessionalForm from '../components/Form/professional';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';

const OfferForm = (props) => {
	const [step, setStep] = useState(0);
	const [offerData, setOfferData] = useState({});

	const auth = useContext(AuthContext);
	const history = useHistory();

	// eslint-disable-next-line
	const { isLoading, error, sendRequest, clearError } = useHttpClient();

	const pushOffer = async (payload) => {
		try {
			await sendRequest(
				process.env.REACT_APP_BACKEND_URL + '/offer/push',
				'POST',
				payload,
				{
					Authorization: `Bearer ${auth.token}`,
					'Content-type': 'application/json',
				}
			);
			history.push('/offer');
			props.closeSideDrawer();
		} catch (err) {}
	};

	const stepHandler = (info) => {
		setOfferData((prevState) => {
			return { ...prevState, ...info };
		});
		if (step === 2) {
			pushOffer({ ...offerData, ...info });
		} else {
			setStep((prevState) => prevState + 1);
		}
	};
	let actualForm = <GeneralForm nextStep={stepHandler} />;
	switch (step) {
		case 1:
			actualForm = <DetailsForm nextStep={stepHandler} />;
			break;
		case 2:
			actualForm = <ProfessionalForm nextStep={stepHandler} />;
			break;
		default:
			break;
	}

	return (
		<React.Fragment>
			{step === 2 && isLoading && <LoadingSpinner />}
			{actualForm}
		</React.Fragment>
	);
};

export default OfferForm;
