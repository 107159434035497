import React, { useReducer, useEffect, useState } from 'react';
import { validate } from '../../utils/validators';

import eyeOn from '../../../assets/icons/eye-on.svg';
import eyeOff from '../../../assets/icons/eye-off.svg';
import './input.css';

const inputReducer = (state, action) => {
	switch (action.type) {
		case 'CHANGE':
			return {
				...state,
				value: action.val,
				isValid: validate(action.val, action.validators),
			};
		case 'TOUCH':
			return {
				...state,
				isTouched: true,
			};
		default:
			return state;
	}
};

const Input = (props) => {
	const [inputState, dispatch] = useReducer(inputReducer, {
		value: props.initialValue || '',
		isTouched: false,
		isValid: props.initialValidity || false,
	});

	const { id, onInput } = props;
	const { value, isValid } = inputState;

	const [passwordMode, setPasswordMode] = useState('password');
	const [eyeMode, setEyeMode] = useState(true);

	useEffect(() => {
		onInput(id, value, isValid);
	}, [id, onInput, value, isValid]);

	const changeHandler = (event) => {
		dispatch({
			type: 'CHANGE',
			val: event.target.value,
			validators: props.validators,
		});
	};

	const touchHandler = () => {
		dispatch({
			type: 'TOUCH',
		});
	};

	const showPasswordHandler = (event) => {
		event.preventDefault();
		setEyeMode(false);
		setPasswordMode('text');
	};

	const hidePasswordHandler = (event) => {
		event.preventDefault();
		setEyeMode(true);
		setPasswordMode('password');
	};

	let element;
	if (props.type === 'text') {
		element = (
			<input
				type={props.type}
				id={props.id}
				placeholder={props.placeholder}
				onChange={changeHandler}
				onBlur={touchHandler}
				value={value}
			/>
		);
	} else if (props.type === 'password') {
		element = (
			<React.Fragment>
				{eyeMode ? (
					<button
						type="button"
						name="mostrar contraseña"
						onClick={showPasswordHandler}
					>
						<img src={eyeOn} alt="" />
					</button>
				) : (
					<button
						type="button"
						name="mostrar contraseña"
						onClick={hidePasswordHandler}
					>
						<img src={eyeOff} alt="" />
					</button>
				)}

				<input
					autoComplete="off"
					autoCorrect="off"
					id={id}
					type={passwordMode}
					onChange={changeHandler}
					onBlur={touchHandler}
					value={value}
				/>
			</React.Fragment>
		);
	} else if (props.type === 'textarea') {
		element = (
			<textarea
				id={id}
				name={id}
				rows={props.rows || 4}
				placeholder={props.placeholder}
				onChange={changeHandler}
				onBlur={touchHandler}
				value={value}
			/>
		);
	}

	return (
		<div className="form-control">
			<label htmlFor={props.id}>{props.label}</label>
			{element}
		</div>
	);
};

export default Input;
