import React from 'react';

import './info.css';

const GeneralInfo = (props) => {
	return (
		<div className="info__container">
			<div className="resume__info-general">
				<div className="resume__info-title">
					<h3>Información General</h3>
				</div>
				<div className="resume__info-body">
					<div className="resume__info-specific">
						<p>Total de usuarios :</p>
						{props.users}
					</div>
					<div className="resume__info-specific">
						<p>Ofertas publicadas :</p>
						{props.offers}
					</div>
					<div className="resume__info-specific">
						<p>Solicitudes recibidas :</p>
						{props.applicants}
					</div>
					{!props.specific && (
						<React.Fragment>
							<div className="resume__info-specific">
								<p>Oficio con más solicitudes :</p>
								{props.job ? props.job : 'Sin información'}
							</div>
							<div className="resume__info-specific">
								<p>Profesión con más solicitudes :</p>
								{props.profession ? props.profession : 'Sin información'}
							</div>
						</React.Fragment>
					)}
				</div>
			</div>
		</div>
	);
};

export default GeneralInfo;
