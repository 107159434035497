import React, { useEffect, useState, useContext } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import { useHttpClient } from '../../../shared/hooks/http-hook';
import { AuthContext } from '../../../shared/context/auth-context';
import { useForm } from '../../../shared/hooks/form-hook';
import { VALIDATOR_IS_NUMBER, VALIDATOR_REQUIRE } from '../../../shared/utils/validators';
import { getChangedInfo, validateInfo } from '../../../shared/utils/change';

import Button from '../../../shared/components/FormElements/button';
import Input from '../../../shared/components/FormElements/input';
import LoadingSpinner from '../../../shared/components/UIElements/LoadingSpinner';

import './form.css';

const ModifyForm = (props) => {
	const auth = useContext(AuthContext);

	const [offerId, setOfferId] = useState(false);
	const [offerInfo, setOfferInfo] = useState(false);
	const [title, setTitle] = useState(false);
	const [heading, setHeading] = useState(false);
	const [description, setDescription] = useState(false);
	const [avaliable, setAvaliable] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);

	//estados de componente
	const [isValid, setIsValid] = useState(false);

	const params = useParams();
	const history = useHistory();

	// eslint-disable-next-line
	const { isLoading, error, sendRequest, clearError } = useHttpClient();

	const inputs = {
		title: {
			value: '',
			isValid: false,
		},
		heading: {
			value: '',
			isValid: false,
		},
		description: {
			value: '',
			isValid: false,
		},
		quantity: {
			value: '',
			isValid: false,
		},
	};
	const [formState, inputHandler, setFormData] = useForm(inputs, false);

	useEffect(() => {
		const getOffer = async (offerId) => {
			try {
				const response = await sendRequest(
					process.env.REACT_APP_BACKEND_URL + `/offer/${offerId}/update`,
					'GET',
					null,
					{
						Authorization: `Bearer ${auth.token}`,
					}
				);
				const offer = response.offer;
				const title = offer.title;
				const heading = offer.heading;
				const description = offer.description;
				const avaliable = offer.quantity;
				setFormData(
					{
						title: {
							value: title,
							isValid: true,
						},
						heading: {
							value: heading,
							isValid: true,
						},
						description: {
							value: description,
							isValid: true,
						},
						quantity: {
							value: avaliable,
							isValid: true,
						},
					},
					true
				);
				setOfferInfo(offer);
				setTitle(title);
				setHeading(heading);
				setDescription(description);
				setAvaliable(avaliable);
				setOfferId(offerId);
			} catch (err) {}
			setIsLoaded(true);
		};
		if (params) {
			const offerId = params.id;
			getOffer(offerId);
		}
	}, [setFormData, params, sendRequest, auth]);

	//validar el cambio del formulario contra la data real del usuario
	useEffect(() => {
		if (isLoaded) {
			const isChanged = !validateInfo(offerInfo, {
				title: formState.inputs.title.value,
				heading: formState.inputs.heading.value,
				description: formState.inputs.description.value,
				quantity: formState.inputs.quantity.value,
			});
			if (isChanged) {
				// ha cambiado
				if (formState.isValid) {
					// el formulario es valido
					setIsValid(true);
				} else {
					// el formulario es invalido
					setIsValid(false);
				}
			} else {
				// no ha cambiado
				setIsValid(false);
			}
		}
	}, [
		title,
		heading,
		description,
		avaliable,
		formState.inputs,
		isLoaded,
		offerInfo,
		setIsValid,
		formState.isValid,
	]);

	const submitHandler = async (event) => {
		event.preventDefault();
		const payload = getChangedInfo(offerInfo, {
			title: formState.inputs.title.value,
			heading: formState.inputs.heading.value,
			description: formState.inputs.description.value,
			quantity: formState.inputs.quantity.value,
		});
		try {
			await sendRequest(
				process.env.REACT_APP_BACKEND_URL + `/offer/${offerId}`,
				'PATCH',
				payload,
				{
					Authorization: `Bearer ${auth.token}`,
					'Content-type': 'application/json',
				}
			);
			props.closeSideDrawer();
			history.push('/offer');
		} catch (err) {}
	};

	return (
		<div className="form__container">
			{isLoaded ? (
				<React.Fragment>
					<div>
						<form onSubmit={submitHandler}>
							<div className="form__inputs">
								<Input
									id="title"
									label="Título: *"
									type="text"
									onInput={inputHandler}
									validators={[VALIDATOR_REQUIRE()]}
									initialValue={title}
									initialValidity={true}
									placeholder={'Título de oferta...'}
								/>
								<Input
									id="heading"
									label="Rubro: *"
									type="text"
									onInput={inputHandler}
									validators={[VALIDATOR_REQUIRE()]}
									initialValue={heading}
									initialValidity={true}
									placeholder={'Rubro de oferta...'}
								/>
								<Input
									id="description"
									label="Descripción: *"
									type="textarea"
									onInput={inputHandler}
									validators={[VALIDATOR_REQUIRE()]}
									initialValue={description}
									initialValidity={true}
									placeholder={'Descripción de oferta...'}
								/>
								<Input
									id="quantity"
									label="Puestos disponibles: *"
									type="text"
									onInput={inputHandler}
									validators={[
										VALIDATOR_REQUIRE(),
										VALIDATOR_IS_NUMBER(),
									]}
									initialValue={avaliable}
									initialValidity={true}
									placeholder={'Puestos disponibles...'}
								/>
							</div>
							<div>
								<Button contained type="submit" disabled={!isValid}>
									Modificar Oferta
								</Button>
							</div>
						</form>
					</div>
				</React.Fragment>
			) : (
				<LoadingSpinner contained />
			)}
		</div>
	);
};

export default ModifyForm;
