import React, { useContext } from 'react';

import { useHttpClient } from '../shared/hooks/http-hook';
import { AuthContext } from '../shared/context/auth-context';
import { useForm } from '../shared/hooks/form-hook';
import {
	VALIDATOR_REQUIRE,
	VALIDATOR_MINLENGTH,
	VALIDATOR_EMAIL,
} from '../shared/utils/validators';

import Button from '../shared/components/FormElements/button';
import Input from '../shared/components/FormElements/input';
import LoadingSpinner from '../shared/components/UIElements/LoadingSpinner';

import Logotipo from '../assets/images/logo.png';

import './login.css';

const Login = () => {
	const auth = useContext(AuthContext);

	const inputs = {
		email: {
			value: '',
			isValid: false,
		},
		password: {
			value: '',
			isValid: false,
		},
	};
	const [formState, inputHandler] = useForm(inputs, false);

	const { isLoading, error, sendRequest, clearError } = useHttpClient();

	const submitHandler = async (event) => {
		event.preventDefault();
		if (error) {
			clearError();
		}
		const formData = {
			email: formState.inputs.email.value,
			password: formState.inputs.password.value,
		};
		try {
			const response = await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/login`,
				'POST',
				formData,
				{
					'Content-type': 'application/json',
				}
			);
			const token = response.token;
			const id = response.id;
			const name = response.name;
			auth.login(token, id, name);
		} catch (err) {}
	};

	return (
		<main className="login">
			{isLoading && <LoadingSpinner />}
			<div className="login__container">
				<div className="login__logotipo">
					<img src={Logotipo} alt="Logotipo HumanTouch" />
				</div>
				<form onSubmit={submitHandler}>
					<div className="login__form-inputs">
						<Input
							id="email"
							label="Email: *"
							type="text"
							onInput={inputHandler}
							validators={[VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()]}
						/>
						<Input
							id="password"
							label="Contraseña: *"
							type="password"
							onInput={inputHandler}
							validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(7)]}
						/>
					</div>
					{error && (
						<div className="login__error">
							<p>{error}</p>
						</div>
					)}
					<div className="login__footer">
						<Button disabled={!formState.isValid} type="submit" contained>
							Iniciar sesión
						</Button>
					</div>
				</form>
			</div>
		</main>
	);
};

export default Login;
