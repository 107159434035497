import React, { useContext } from 'react';
import ReactDom from 'react-dom';

import { AuthContext } from '../../context/auth-context';

import LogoutIcon from '../../../assets/icons/logout.svg';

import './menu.css';

const Icon = (props) => {
	return (
		<div className="Icon__container">
			<img src={props.src} alt="" />
		</div>
	);
};

const Menu = () => {
	const auth = useContext(AuthContext);
	const content = (
		<div className="menu">
			<ul className="menu__items">
				<li className="menu__item">
					<button onClick={auth.logout}>
						<Icon src={LogoutIcon} />
						<p>Cerrar sesión</p>
					</button>
				</li>
			</ul>
		</div>
	);
	return ReactDom.createPortal(content, document.getElementById('menu-hook'));
};

export default Menu;
