import { useState, useEffect, useCallback } from "react";

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(false);
  const [id, setId] = useState(false);
  const [name, setName] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();

  const logout = useCallback(() => {
    localStorage.removeItem("admin-data");
    setTokenExpirationDate(null);
    setId(null);
    setName(null);
    setToken(null);
    clearTimeout(logoutTimer);
  }, []);

  const login = useCallback(
    (token, id, name, expirationDate) => {
      setId(id);
      setName(name);
      const tokenExpirationDate =
        expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);
      setTokenExpirationDate(tokenExpirationDate);
      localStorage.setItem(
        "admin-data",
        JSON.stringify({
          token: token,
          id: id,
          name: name,
          expiration: tokenExpirationDate.toISOString(),
        })
      );
      logoutTimer = setTimeout(logout, tokenExpirationDate);
      setToken(token);
    },
    [logout]
  );

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("admin-data"));
    if (
      storedData &&
      storedData.token &&
      storedData.id &&
      storedData.name &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.token,
        storedData.id,
        storedData.name,
        new Date(storedData.expiration)
      );
    }
  }, [login]);

  return { token, id, name, login, logout };
};
