import React from 'react';

import './info.css';

const RentInfo = (props) => {
	return (
		<div className="info__container">
			<div className="resume__info">
				<div className="resume__info-title">
					<h3>Información Rentas</h3>
				</div>
				<div className="resume__info-body">
					<div className="resume__info-specific">
						<p>Renta promedio esperada :</p>
						{props.averageRentAwait
							? props.averageRentAwait
							: 'Sin información'}
					</div>
					<div className="resume__info-specific">
						<p>Renta promedio ofrecida :</p>
						{props.averageRentOffered
							? props.averageRentOffered
							: 'Sin información'}
					</div>
				</div>
			</div>
		</div>
	);
};

export default RentInfo;
