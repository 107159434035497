import React from 'react';

import Button from '../../shared/components/FormElements/button';

import AdministrativeData from './administrativeData';

import Clock from '../../assets/icons/clock.svg';
import Experience from '../../assets/icons/experience.svg';
import Contract from '../../assets/icons/contract.svg';
import Location from '../../assets/icons/location.svg';
import QuantityIcon from '../../assets/icons/quantity.svg';
import ApplicantIcon from '../../assets/icons/applicant.svg';
import Views from '../../assets/icons/eye-black.svg';
import Rent from '../../assets/icons/rent.svg';
import WorkIcon from '../../assets/icons/work.svg';

import './offerBody.css';

const OfferBody = (props) => {
	const parseDate = (date) => {
		const today = new Date(date);
		let dd = today.getDate();
		let mm = today.getMonth() + 1;
		const yyyy = today.getFullYear();
		if (dd < 10) {
			dd = '0' + dd;
		}
		if (mm < 10) {
			mm = '0' + mm;
		}
		return dd + '/' + mm + '/' + yyyy;
	};
	return (
		<div className="info-container">
			<div className="info-container__text">
				<div className="info-container__title">
					<h2>{props.title}</h2>
					<div className="info-container__sub-title">
						<p>{props.heading}</p>
						<p>{parseDate(props.created)}</p>
					</div>
				</div>
				<p>{props.description}</p>
			</div>
			<div className="info-container__extra">
				<div className="info-container__extra-title">
					<h2>Información Adicional</h2>
				</div>
				<div className="info-container__especific">
					<div className="info-container__specific-info">
						<img src={Experience} alt="Icono de experiencia" />
						<p>
							<b>Experiencia: </b>
							{props.experience ? 'No Requerida' : props.antiquity}
						</p>
					</div>
					<div className="info-container__specific-info">
						<img src={Clock} alt="Icono de jornada" />
						<p>
							<b>Jornada: </b>
							{props.workday}
						</p>
					</div>
					<div className="info-container__specific-info">
						<img src={Contract} alt="Icono de Contrato" />
						<p>
							<b>Tipo de contrato: </b>
							{props.aggrementType}
						</p>
					</div>
					<div className="info-container__specific-info">
						<img src={Rent} alt="Icono de Contrato" />
						<p>
							<b>Renta estimada: </b>
							{props.offeredRent}
						</p>
					</div>
					<div className="info-container__specific-info">
						<img src={Location} alt="" />
						<p className="info-container__comuna">
							{props.region}, {props.comuna}
						</p>
					</div>
				</div>
			</div>
			<div className="info-container__administrative">
				<AdministrativeData>
					<div>
						<div className="info-container__admin-title">
							<h2>Datos Administrativos</h2>
						</div>
					</div>
					<div>
						<div className="info-container__specific-info">
							<img src={QuantityIcon} alt="Icono " />
							<p>
								<b>Puestos disponibles: </b>
								{props.quantity}
							</p>
						</div>
						<div className="info-container__specific-info">
							{props.job && (
								<React.Fragment>
									<img src={WorkIcon} alt="Icono " />
									<p>
										<b>Oficio: </b>
										{props.job.name}
									</p>
								</React.Fragment>
							)}
							{props.profession && (
								<React.Fragment>
									<img src={WorkIcon} alt="Icono " />
									<p>
										<b>Profesión: </b>
										{props.profession.name}
									</p>
								</React.Fragment>
							)}
						</div>
						<div className="info-container__specific-info">
							<img src={ApplicantIcon} alt="Icono " />
							<p>
								<b>Solicitudes recibidas: </b>
								{props.applicants}
							</p>
						</div>
						<div className="info-container__specific-info">
							<img src={Views} alt="Icono " />
							<p>
								<b>N° de Visualizaciones: </b>
								{props.views}
							</p>
						</div>
					</div>
				</AdministrativeData>
				<div className="info-container__button ">
					<Button contained onClick={props.applyHandler}>
						Lista de postulantes
					</Button>
				</div>
			</div>
		</div>
	);
};

export default OfferBody;
