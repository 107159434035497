import React from 'react';

import { useOffer } from '../../shared/hooks/offer-hook';

import Button from '../../shared/components/FormElements/button';

import './toolsList.css';

const ToolsList = (props) => {
	const { deleteOffer } = useOffer();

	return (
		<div className="toollist__container">
			<div className="drawer__menu-nav-margin">
				<Button outlined onClick={props.goToUpdateOffer}>
					Modificar oferta
				</Button>
			</div>
			<div className="drawer__menu-nav-margin">
				<Button contained onClick={() => deleteOffer(props.offerId)}>
					Eliminar oferta
				</Button>
			</div>
		</div>
	);
};

export default ToolsList;
