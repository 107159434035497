import React, { useEffect, useState, useContext } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

import MainNavigation from '../../shared/components/Navigation/MainNavigation';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ScrollToTop from '../../shared/utils/scrollToTop';
import OfferBody from '../components/offerBody';
import WrongOffer from '../components/wrongOffer';

import './offerDetails.css';
import GoBack from '../../shared/components/Utils/goBack';

const OfferInfo = (props) => {
	const auth = useContext(AuthContext);

	const [offerInfo, setOfferInfo] = useState(false);
	const [isLoaded, setIsLoaded] = useState(true);

	const params = useParams();
	const history = useHistory();

	// eslint-disable-next-line
	const { isLoading, error, sendRequest, clearError } = useHttpClient();

	useEffect(() => {
		const getOffer = async (offerId) => {
			try {
				const response = await sendRequest(
					process.env.REACT_APP_BACKEND_URL + '/offer/' + offerId,
					'GET',
					null,
					{
						Authorization: `Bearer ${auth.token}`,
					}
				);
				setOfferInfo(response.offer);
			} catch (err) {}
			setIsLoaded(true);
		};
		if (params) {
			const offerId = params.id;
			getOffer(offerId);
		}
	}, [params, sendRequest, auth]);

	const viewApplicantsHandler = (id) => {
		history.push(`/offer/${id}/applicants/`);
	};

	return (
		<React.Fragment>
			<ScrollToTop />
			<MainNavigation />
			<main className="offer-detail__container">
				<GoBack />
				{isLoaded ? (
					<React.Fragment>
						{offerInfo ? (
							<OfferBody
								title={offerInfo.title}
								heading={offerInfo.heading}
								description={offerInfo.description}
								experience={offerInfo.experience}
								workday={offerInfo.workday}
								offeredRent={offerInfo.rent}
								region={offerInfo.region}
								comuna={offerInfo.comuna}
								job={offerInfo.job}
								profession={offerInfo.profession}
								aggrementType={offerInfo.aggrementType}
								antiquity={offerInfo.antiquity}
								created={offerInfo.created}
								quantity={offerInfo.quantity}
								applicants={offerInfo.applicants.length}
								views={offerInfo.views.length}
								applyHandler={() => viewApplicantsHandler(offerInfo._id)}
							/>
						) : (
							<WrongOffer />
						)}
					</React.Fragment>
				) : (
					<LoadingSpinner contained />
				)}
			</main>
		</React.Fragment>
	);
};

export default OfferInfo;
