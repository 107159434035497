import { useCallback, useContext } from 'react';

import { useHistory } from 'react-router-dom';

import { useAlert } from 'react-alert';

import { AuthContext } from '../context/auth-context';
import { useHttpClient } from './http-hook';

export const useOffer = () => {
	const auth = useContext(AuthContext);
	const history = useHistory();
	const alert = useAlert();
	// eslint-disable-next-line
	const { isLoading, error, sendRequest, clearError } = useHttpClient();

	const getApplicants = useCallback(
		async (offerId, token = auth.token) => {
			try {
				const response = await sendRequest(
					process.env.REACT_APP_BACKEND_URL + `/offer/${offerId}/aplicants`,
					'GET',
					null,
					{
						Authorization: `Bearer ${token}`,
					}
				);
				if (response.isValid) {
					alert.success('Su solicitud fue recibida.');
				} else {
					alert.success('Usted ha enviado una solicitud previamente.');
				}
			} catch (err) {}
		},
		[alert, sendRequest, auth]
	);
	const deleteOffer = useCallback(
		async (offerId, token = auth.token) => {
			try {
				await sendRequest(
					process.env.REACT_APP_BACKEND_URL + `/offer/${offerId}`,
					'DELETE',
					null,
					{
						Authorization: `Bearer ${token}`,
					}
				);
				alert.success('La oferta fue eliminada.');
				history.push('/offer');
			} catch (err) {}
		},
		[alert, sendRequest, auth, history]
	);
	const updateOffer = useCallback(
		async (offerId, token = auth.token, payload) => {
			try {
				await sendRequest(
					process.env.REACT_APP_BACKEND_URL + `/offer/${offerId}`,
					'PATCH',
					payload,
					{
						Authorization: `Bearer ${token}`,
					}
				);
				alert.success('La oferta fue actualizada.');
				history.push('/offer');
			} catch (err) {}
		},
		[alert, sendRequest, auth, history]
	);

	return { getApplicants, deleteOffer, updateOffer };
};
