import React from 'react';

import './wrongOffer.css'

const WrongOffer = () => {
	return (
		<div className="lost-page">
			<h1>(⩾﹏⩽)</h1>
			<h3>Ups!</h3>
			<p>La página que estás buscando ya no está disponible</p>
		</div>
	);
};

export default WrongOffer;
