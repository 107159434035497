import React from 'react';

import './quickFilter.css';

const QuickFilter = (props) => {
	return (
		<div className="scroll">
			<div className="scroll_title">
				<p>{props.title}</p>
			</div>
			<div className="scroll__options">
				{props.options ? (
					props.options.length > 0 ? (
						props.options.map((opcion) => (
							<React.Fragment key={opcion.name}>
								{!props.isFilter ? (
									<div className="no-filter__item">
										<p>{opcion.name}</p>
									</div>
								) : (
									<div
										className="item"
										onClick={() => props.onClick(opcion._id)}
									>
										<p>{opcion.name}</p>
									</div>
								)}
							</React.Fragment>
						))
					) : (
						<div>Sin definir...</div>
					)
				) : (
					<div>Cargando...</div>
				)}
			</div>
		</div>
	);
};

export default QuickFilter;
