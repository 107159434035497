export const regiones = [
	{
		name: 'Región de Arica y Parinacota',
		comunas: [
			{ name: 'Arica' },
			{ name: 'Camarones' },
			{ name: 'Putre' },
			{ name: 'General Lagos' },
		],
	},
	{
		name: 'Región de Tarapacá',
		comunas: [
			{ name: 'Iquique' },
			{ name: 'Alto Hospicio' },
			{ name: 'Camiña' },
			{ name: 'Colchane' },
			{ name: 'Huara' },
			{ name: 'Pica y Pozo Almonte' },
		],
	},
	{
		name: 'Región de Antofagasta',
		comunas: [
			{ name: 'Antofagasta' },
			{ name: 'Mejillones' },
			{ name: 'Sierra Gorda' },
			{ name: 'Taltal' },
			{ name: 'Calama' },
			{ name: 'Ollagüe' },
			{ name: 'San Pedro de Atacama' },
		],
	},
	{
		name: 'Región de Atacama',
		comunas: [
			{ name: 'Chañaral' },
			{ name: 'Diego de Almagro' },
			{ name: 'Caldera' },
			{ name: 'Copiapó' },
			{ name: 'Tierra Amarilla' },
			{ name: 'Alto del Carmen' },
			{ name: 'Freirina' },
			{ name: 'Huasco' },
			{ name: 'Vallenar' },
		],
	},
	{
		name: 'Región de Coquimbo',
		comunas: [
			{ name: 'Canela' },
			{ name: 'Illapel' },
			{ name: 'Los Vilos' },
			{ name: 'Salamanca' },
			{ name: 'Andacollo' },
			{ name: 'Coquimbo' },
			{ name: 'La Higuera' },
			{ name: 'La Serena' },
			{ name: 'Paihuano' },
			{ name: 'Vicuña' },
			{ name: 'Combarbalá' },
			{ name: 'Monte Patria' },
			{ name: 'Ovalle' },
			{ name: 'Punitaqui' },
			{ name: 'Río Hurtado' },
		],
	},
	{
		name: 'Región de Valparaíso',
		comunas: [
			{ name: 'Isla de Pascua' },
			{ name: 'Calle Larga' },
			{ name: 'Los Andes' },
			{ name: 'Rinconada' },
			{ name: 'San Esteban' },
			{ name: 'Cabildo' },
			{ name: 'La Ligua' },
			{ name: 'Papudo' },
			{ name: 'Petorca' },
			{ name: 'Zapallar' },
			{ name: 'Hijuelas' },
			{ name: 'La Calera' },
			{ name: 'La Cruz' },
			{ name: 'Nogales' },
			{ name: 'Quillota' },
			{ name: 'Algarrobo' },
			{ name: 'Cartagena' },
			{ name: 'El Quisco' },
			{ name: 'El Tabo' },
			{ name: 'San Antonio' },
			{ name: 'Santo Domingo' },
			{ name: 'Catemu' },
			{ name: 'Llay-Llay' },
			{ name: 'Panquehue' },
			{ name: 'Putaendo' },
			{ name: 'San Felipe' },
			{ name: 'Santa María' },
			{ name: 'Casablanca' },
			{ name: 'Concón' },
			{ name: 'Juan Fernández' },
			{ name: 'Puchuncaví' },
			{ name: 'Quintero' },
			{ name: 'Valparaíso' },
			{ name: 'Viña del Mar' },
			{ name: 'Limache' },
			{ name: 'Olmué' },
			{ name: 'Quilpué' },
			{ name: 'Villa Alemana' },
		],
	},
	{
		name: 'Región Metropolitana de Santiago',
		comunas: [
			{ name: 'Cerrillos' },
			{ name: 'Cerro Navia' },
			{ name: 'Conchalí' },
			{ name: 'El Bosque' },
			{ name: 'Estación Central' },
			{ name: 'Huechuraba' },
			{ name: 'Independencia' },
			{ name: 'La Cisterna' },
			{ name: 'La Florida' },
			{ name: 'La Granja' },
			{ name: 'La Pintana' },
			{ name: 'La Reina' },
			{ name: 'Las Condes' },
			{ name: 'Lo Barnechea' },
			{ name: 'Lo Espejo' },
			{ name: 'Lo Prado' },
			{ name: 'Macul' },
			{ name: 'Maipú' },
			{ name: 'Ñuñoa' },
			{ name: 'Pedro Aguirre Cerda' },
			{ name: 'Peñalolén' },
			{ name: 'Providencia' },
			{ name: 'Pudahuel' },
			{ name: 'Quilicura' },
			{ name: 'Quinta Normal' },
			{ name: 'Recoleta' },
			{ name: 'Renca' },
			{ name: 'San Joaquín' },
			{ name: 'San Miguel' },
			{ name: 'San Ramón' },
			{ name: 'Santiago' },
			{ name: 'Vitacura' },
		],
	},
	{
		name: 'Región del Libertador General Bernardo O’Higgins',
		comunas: [
			{ name: 'Codegua' },
			{ name: 'Coinco' },
			{ name: 'Coltauco' },
			{ name: 'Doñihue' },
			{ name: 'Graneros' },
			{ name: 'Las Cabras' },
			{ name: 'Machalí' },
			{ name: 'Malloa' },
			{ name: 'Mostazal' },
			{ name: 'Olivar' },
			{ name: 'Peumo' },
			{ name: 'Pichidegua' },
			{ name: 'Quinta de Tilcoco' },
			{ name: 'Rancagua' },
			{ name: 'Rengo' },
			{ name: 'Requínoa' },
			{ name: 'San Vicente de Tagua Tagua' },
			{ name: 'La Estrella' },
			{ name: 'Litueche' },
			{ name: 'Marchigüe' },
			{ name: 'Navidad' },
			{ name: 'Paredones' },
			{ name: 'Pichilemu' },
			{ name: 'Chépica' },
			{ name: 'Chimbarongo' },
			{ name: 'Lolol' },
			{ name: 'Nancagua' },
			{ name: 'Palmilla' },
			{ name: 'Peralillo' },
			{ name: 'Placilla' },
			{ name: 'Pumanque' },
			{ name: 'San Fernando' },
			{ name: 'Santa Cruz' },
		],
	},
	{
		name: 'Región del Maule',
		comunas: [
			{ name: 'Cauquenes' },
			{ name: 'Chanco' },
			{ name: 'Pelluhue' },
			{ name: 'Curicó' },
			{ name: 'Hualañé' },
			{ name: 'Licantén' },
			{ name: 'Molina' },
			{ name: 'Rauco' },
			{ name: 'Romeral' },
			{ name: 'Sagrada Familia' },
			{ name: 'Teno' },
			{ name: 'Vichuquén' },
			{ name: 'Colbún' },
			{ name: 'Linares' },
			{ name: 'Longaví' },
			{ name: 'Parral' },
			{ name: 'Retiro' },
			{ name: 'San Javier' },
			{ name: 'Villa Alegre' },
			{ name: 'Yerbas Buenas' },
			{ name: 'Constitución' },
			{ name: 'Curepto' },
			{ name: 'Empedrado' },
			{ name: 'Maule' },
			{ name: 'Pelarco' },
			{ name: 'Pencahue' },
			{ name: 'Río Claro' },
			{ name: 'San Clemente' },
			{ name: 'San Rafael' },
			{ name: 'Talca' },
		],
	},
	{
		name: 'Región del Ñuble',
		comunas: [
			{ name: 'Cobquecura' },
			{ name: 'Coelemu' },
			{ name: 'Ninhue' },
			{ name: 'Portezuelo' },
			{ name: 'Quirihue' },
			{ name: 'Ránquil' },
			{ name: 'Trehuaco' },
			{ name: 'Bulnes' },
			{ name: 'Chillán Viejo' },
			{ name: 'Chillán' },
			{ name: 'El Carmen' },
			{ name: 'Pemuco' },
			{ name: 'Pinto' },
			{ name: 'Quillón' },
			{ name: 'San Ignacio' },
			{ name: 'Yungay' },
			{ name: 'Coihueco' },
			{ name: 'Ñiquén' },
			{ name: 'San Carlos' },
			{ name: 'San Fabián' },
			{ name: 'San Nicolás' },
		],
	},
	{
		name: 'Región del Biobío',
		comunas: [
			{ name: 'Arauco' },
			{ name: 'Cañete' },
			{ name: 'Contulmo' },
			{ name: 'Curanilahue' },
			{ name: 'Lebu' },
			{ name: 'Los Álamos' },
			{ name: 'Tirúa' },
			{ name: 'Alto Biobío' },
			{ name: 'Antuco' },
			{ name: 'Cabrero' },
			{ name: 'Laja' },
			{ name: 'Los Ángeles' },
			{ name: 'Mulchén' },
			{ name: 'Nacimiento' },
			{ name: 'Negrete' },
			{ name: 'Quilaco' },
			{ name: 'Quilleco' },
			{ name: 'San Rosendo' },
			{ name: 'Santa Bárbara' },
			{ name: 'Tucapel' },
			{ name: 'Yumbel' },
			{ name: 'Chiguayante' },
			{ name: 'Concepción' },
			{ name: 'Coronel' },
			{ name: 'Florida' },
			{ name: 'Hualpén' },
			{ name: 'Hualqui' },
			{ name: 'Lota' },
			{ name: 'Penco' },
			{ name: 'San Pedro de la Paz' },
			{ name: 'Santa Juana' },
			{ name: 'Talcahuano' },
			{ name: 'Tomé' },
		],
	},
	{
		name: 'Región de La Araucanía',
		comunas: [
			{ name: 'Carahue' },
			{ name: 'Cholchol' },
			{ name: 'Cunco' },
			{ name: 'Curarrehue' },
			{ name: 'Freire' },
			{ name: 'Galvarino' },
			{ name: 'Gorbea' },
			{ name: 'Lautaro' },
			{ name: 'Loncoche' },
			{ name: 'Melipeuco' },
			{ name: 'Nueva Imperial' },
			{ name: 'Padre Las Casas' },
			{ name: 'Perquenco' },
			{ name: 'Pitrufquén' },
			{ name: 'Pucón' },
			{ name: 'Puerto Saavedra' },
			{ name: 'Temuco' },
			{ name: 'Teodoro Schmidt' },
			{ name: 'Toltén' },
			{ name: 'Vilcún' },
			{ name: 'Villarrica' },
			{ name: 'Angol' },
			{ name: 'Collipulli' },
			{ name: 'Curacautín' },
			{ name: 'Ercilla' },
			{ name: 'Lonquimay' },
			{ name: 'Los Sauces' },
			{ name: 'Lumaco' },
			{ name: 'Purén' },
			{ name: 'Renaico' },
			{ name: 'Traiguén' },
			{ name: 'Victoria' },
		],
	},
	{
		name: 'Región de Los Ríos',
		comunas: [
			{ name: 'Corral' },
			{ name: 'Lanco' },
			{ name: 'Los Lagos' },
			{ name: 'Máfil' },
			{ name: 'Mariquina' },
			{ name: 'Paillaco' },
			{ name: 'Panguipulli' },
			{ name: 'Valdivia' },
			{ name: 'Futrono' },
			{ name: 'La Unión' },
			{ name: 'Lago Ranco' },
			{ name: 'Río Bueno' },
		],
	},
	{
		name: 'Región de Los Lagos',
		comunas: [
			{ name: 'Ancud' },
			{ name: 'Castro' },
			{ name: 'Chonchi' },
			{ name: 'Curaco de Vélez' },
			{ name: 'Dalcahue' },
			{ name: 'Puqueldón' },
			{ name: 'Queilén' },
			{ name: 'Quemchi' },
			{ name: 'Quellón' },
			{ name: 'Quinchao' },
			{ name: 'Calbuco' },
			{ name: 'Cochamó' },
			{ name: 'Fresia' },
			{ name: 'Frutillar' },
			{ name: 'Llanquihue' },
			{ name: 'Los Muermos' },
			{ name: 'Maullín' },
			{ name: 'Puerto Montt' },
			{ name: 'Puerto Varas' },
			{ name: 'Osorno' },
			{ name: 'Puerto Octay' },
			{ name: 'Purranque' },
			{ name: 'Puyehue' },
			{ name: 'Río Negro' },
			{ name: 'San Juan de la Costa' },
			{ name: 'San Pablo' },
			{ name: 'Chaitén' },
			{ name: 'Futaleufú' },
			{ name: 'Hualaihué' },
			{ name: 'Palena' },
		],
	},
	{
		name: 'Región de Aysén del General Carlos Ibáñez del Campo',
		comunas: [
			{ name: 'Aysén' },
			{ name: 'Cisnes' },
			{ name: 'Guaitecas' },
			{ name: 'Cochrane' },
			{ name: "O'Higgins" },
			{ name: 'Tortel' },
			{ name: 'Coyhaique' },
			{ name: 'Lago Verde' },
			{ name: 'Chile Chico' },
			{ name: 'Río Ibáñez' },
		],
	},
	{
		name: 'Región de Magallanes y la Antártica Chilena',
		comunas: [
			{ name: 'Antártica' },
			{ name: 'Cabo de Hornos' },
			{ name: 'Laguna Blanca' },
			{ name: 'Punta Arenas' },
			{ name: 'Río Verde' },
			{ name: 'San Gregorio' },
			{ name: 'Porvenir' },
			{ name: 'Primavera' },
			{ name: 'Timaukel' },
			{ name: 'Natales' },
			{ name: 'Torres del Paine' },
		],
	},
];
