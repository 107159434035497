import React from 'react';

import './output.css';

const Output = (props) => {
	return (
		<div className="output" style={{ marginBottom: !!props.last ? 0 : 5 }}>
			<p className="output__title">{props.title}</p>
			<p className="output__text">{props.text || 'Sin definir...'}</p>
		</div>
	);
};

export default Output;
