import { useCallback, useState, useRef, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useAlert } from 'react-alert';

export const useHttpClient = () => {
	const alert = useAlert();

	const location = useLocation();

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(false);

	const activeHttpRequest = useRef([]);

	const sendRequest = useCallback(
		async (url, method = 'GET', body = null, headers = {}) => {
			setIsLoading(true);
			const httpAbortCtrl = new AbortController();
			activeHttpRequest.current.push(httpAbortCtrl);

			let newBody = body;

			if (method === 'POST' || method === 'PATCH') {
				newBody = JSON.stringify(body);
			}

			try {
				const response = await fetch(url, {
					method,
					headers,
					body: newBody,
					signal: httpAbortCtrl.signal,
				});

				const responseData = await response.json();

				activeHttpRequest.current = activeHttpRequest.current.filter(
					(reqCtrl) => reqCtrl !== httpAbortCtrl
				);

				if (!response.ok) {
					throw new Error(responseData.message);
				}

				setIsLoading(false);
				return responseData;
			} catch (err) {
				if (location.pathname !== '/login') {
					alert.error(err.message);
				} else {
					setError(err.message);
				}
				setIsLoading(false);
			}
		},
		[location, alert]
	);

	const clearError = () => {
		setError(null);
	};

	useEffect(() => {
		return () => {
			activeHttpRequest.current.forEach((abortCtrl) => abortCtrl.abort());
		};
	}, []);

	return { isLoading, error, sendRequest, clearError };
};
