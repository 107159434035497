export const rentas = [
	{ name: '$ 350.000 CLP - $ 500.000 CLP' },
	{ name: '$ 501.000 CLP - $ 700.000 CLP' },
	{ name: '$ 701.000 CLP - $ 900.000 CLP' },
	{ name: '$ 901.000 CLP - $ 1.100.000 CLP' },
	{ name: '$ 1.101.000 CLP - $ 1.300.000 CLP' },
	{ name: '$ 1.301.000 CLP - $ 1.500.000 CLP' },
	{ name: '$ 1.501.000 CLP - $ 2.000.000 CLP' },
	{ name: '$ 2.001.000 CLP - $ 2.500.000 CLP' },
	{ name: '$ 2.501.000 CLP - $ 3.000.000 CLP' },
	{ name: '$ 3.001.000 CLP - Más de $ 3.001.000 CLP' },
];
