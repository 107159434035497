import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { CircularProgress } from '@material-ui/core';
import Backdrop from './Backdrop';

import './LoadingSpinner.css';

const useStylesFacebook = makeStyles((theme) => ({
	color: {
		color: '#D31313',
	},
}));

const LoadingSpinner = (props) => {
	const classes = useStylesFacebook();
	if (props.contained) {
		return (
			<div className="spinner_contained">
				<CircularProgress
					variant="indeterminate"
					color="primary"
					className={classes.color}
				/>
			</div>
		);
	}
	return (
		<Backdrop onClick={null}>
			<div className="center" style={{ height: '100%' }}>
				<CircularProgress
					variant="indeterminate"
					color="primary"
					className={classes.color}
				/>
			</div>
		</Backdrop>
	);
};
export default LoadingSpinner;
