import React, { useEffect, useState, useContext } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { AuthContext } from '../../../shared/context/auth-context';
import { getInitials } from '../../utils/initials';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MainHeader from './MainHeader';
import SideDrawer from './SideDrawer';
import Backdrop from '../UIElements/Backdrop';
import Button from '../FormElements/button';
import FilterForm from '../../../Filter/components/filterForm';
import OfferForm from '../../../Offer/pages/offerForm';
import UpdateOfferForm from '../../../Offer/components/Form/updateOfferForm';
import ResumeFilterForm from '../../../Filter/components/resumeFilterForm';
import ToolsList from '../../../Offer/components/toolsList';
import Menu from '../UIElements/menu';

import LogotipoBlanco from '../../../assets/images/logo-svg.svg';
import DropIcon from '../../../assets/icons/arrow_down.svg';

import './MainNavigation.css';

const MainNavigation = () => {
	const auth = useContext(AuthContext);
	const history = useHistory();
	const location = useLocation();

	const [drawerIsOpen, setDrawerIsOpen] = useState(false);
	const [filterDrawerIsOpen, setFilterDrawerIsOpen] = useState(false);
	const [resumeFilterDrawerIsOpen, setResumeFilterDrawerIsOpen] = useState(false);
	const [isOfferFormOpen, setIsOfferFormOpen] = useState(false);
	const [isModifyOfferFormOpen, setIsModifyOfferFormOpen] = useState(false);
	const [isToolDrawerOpen, setIsToolDrawerOpen] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const [isOffersPage, setIsOffersPage] = useState(false);
	const [isResumePage, setIsResumePage] = useState(false);
	const [isDetailsPage, setIsDetailsPage] = useState(false);
	const [isApplicantsPage, setIsApplicantsPage] = useState(false);
	const [isApplicantListPage, setIsApplicantListPage] = useState(false);
	const [initials, setInitials] = useState('');

	useEffect(() => {
		const currentPage = location.pathname;

		if (currentPage === '/offer') {
			setIsOffersPage(true);
			setIsResumePage(false);
			setIsDetailsPage(false);
			setIsApplicantsPage(false);
			setIsApplicantListPage(false);
		} else if (currentPage === '/resume') {
			setIsResumePage(true);
			setIsOffersPage(false);
			setIsDetailsPage(false);
			setIsApplicantsPage(false);
			setIsApplicantListPage(false);
		} else if (currentPage !== '/resume' || currentPage !== 'offer') {
			const urlSplit = currentPage.split('/');
			if (urlSplit.length === 5) {
				if (urlSplit[4] !== '') {
					setIsApplicantsPage(true);
					setIsOffersPage(false);
					setIsResumePage(false);
					setIsDetailsPage(false);
					setIsApplicantListPage(false);
				} else {
					setIsApplicantListPage(true);
					setIsOffersPage(false);
					setIsResumePage(false);
					setIsDetailsPage(false);
					setIsApplicantsPage(false);
				}
			} else {
				const offerId = urlSplit[2];
				setIsDetailsPage(offerId);
				setIsOffersPage(false);
				setIsResumePage(false);
				setIsApplicantsPage(false);
				setIsApplicantListPage(false);
			}
		} else {
			setIsDetailsPage(false);
			setIsOffersPage(false);
			setIsApplicantListPage(false);
			setIsApplicantsPage(false);
			setIsResumePage(false);
		}

		try {
			const initials = getInitials(auth.name);
			setInitials(initials);
		} catch (err) {
			auth.logout();
		}
	}, [location, auth]);

	const openDrawerHandler = () => {
		setDrawerIsOpen(true);
	};
	const openFilterDrawerHandler = () => {
		setFilterDrawerIsOpen(true);
	};
	const closeDrawerHandler = () => {
		setDrawerIsOpen(false);
		setFilterDrawerIsOpen(false);
		setIsOfferFormOpen(false);
		setIsToolDrawerOpen(false);
		setResumeFilterDrawerIsOpen(false);
		setIsModifyOfferFormOpen(false);
	};

	const openOfferFormHandler = () => {
		setIsOfferFormOpen(true);
	};
	const openModifyOfferFormHandler = () => {
		setIsModifyOfferFormOpen(true);
		setIsToolDrawerOpen(false);
	};
	const openResumeFilterDrawerHandler = () => {
		setResumeFilterDrawerIsOpen(true);
	};

	const openToolDrawer = () => {
		setIsToolDrawerOpen(true);
	};

	const openMenuHandler = () => {
		closeDrawerHandler();
		setIsMenuOpen(true);
	};
	const closeMenuHandler = () => {
		setIsMenuOpen(false);
	};

	const logoutUser = () => {
		closeDrawerHandler();
		auth.logout();
		history.push('/login');
	};

	const goToPage = (location) => {
		closeDrawerHandler();
		history.push(location);
	};

	const goBack = () => {
		closeDrawerHandler();
		history.goBack();
	};

	let element;
	if (isOffersPage) {
		element = (
			<Button contained onClick={openFilterDrawerHandler}>
				Filtrar
			</Button>
		);
	} else if (isResumePage) {
		element = (
			<Button contained onClick={openResumeFilterDrawerHandler}>
				Filtrar
			</Button>
		);
	} else if (isDetailsPage || isApplicantListPage) {
		element = (
			<Button contained onClick={() => goToPage('/offer')}>
				Ir a ofertas
			</Button>
		);
	} else if (isApplicantsPage) {
		element = (
			<Button contained onClick={goBack}>
				Postulantes
			</Button>
		);
	}

	return (
		<React.Fragment>
			{drawerIsOpen |
				filterDrawerIsOpen |
				isOfferFormOpen |
				isToolDrawerOpen |
				resumeFilterDrawerIsOpen |
				isModifyOfferFormOpen && <Backdrop onClick={closeDrawerHandler} />}
			<SideDrawer show={drawerIsOpen}>
				<div className="drawer__menu">
					<div className="drawer__menu-profile-details">
						<div className="drawer__menu-profile-initials">
							<p>{initials}</p>
						</div>
						<div className="drawer__menu-profile-user">
							<h5>{auth.name}</h5>
							<p>Administrador</p>
						</div>
					</div>
					<hr className="drawer__menu-profile-hr" />
					<nav className="drawer__menu-nav">
						<Button contained onClick={() => goToPage('/resume')}>
							Resumen general
						</Button>
						<div className="drawer__menu-nav-margin">
							<Button contained onClick={() => goToPage('/offer')}>
								Ofertas activas
							</Button>
						</div>
						<div className="drawer__menu-nav-margin">
							<Button outlined onClick={logoutUser}>
								Cerrar sesión
							</Button>
						</div>
					</nav>
				</div>
			</SideDrawer>
			<SideDrawer show={filterDrawerIsOpen}>
				<FilterForm closeSideDrawer={closeDrawerHandler} />
			</SideDrawer>
			<SideDrawer show={resumeFilterDrawerIsOpen}>
				<ResumeFilterForm closeSideDrawer={closeDrawerHandler} />
			</SideDrawer>
			<SideDrawer show={isToolDrawerOpen}>
				<ToolsList
					offerId={isDetailsPage}
					closeSideDrawer={closeDrawerHandler}
					goToUpdateOffer={openModifyOfferFormHandler}
				/>
			</SideDrawer>
			<SideDrawer show={isOfferFormOpen}>
				<OfferForm closeSideDrawer={closeDrawerHandler} />
			</SideDrawer>
			<SideDrawer show={isModifyOfferFormOpen}>
				<UpdateOfferForm closeSideDrawer={closeDrawerHandler} />
			</SideDrawer>
			<MainHeader>
				<button className="main-navigation__menu-btn" onClick={openDrawerHandler}>
					<span className="main-navigation__span-1" />
					<span className="main-navigation__span-2" />
					<span className="main-navigation__span-3" />
				</button>
				<div className="main-navigation__logotipo">
					<img src={LogotipoBlanco} alt="HumanTouch Logotipo" />
				</div>
				<div className="main-navigation__button-container">
					{isResumePage && (
						<div className="main-navigation__button-resume">
							<Button contained onClick={() => goToPage('/offer')}>
								Ofertas activas
							</Button>
						</div>
					)}
					{isOffersPage && (
						<React.Fragment>
							<div className="main-navigation__button-resume">
								<Button contained onClick={() => goToPage('/resume')}>
									Resumen general
								</Button>
							</div>
							<div className="main-navigation__button-publicar">
								<Button contained onClick={openOfferFormHandler}>
									Publicar oferta
								</Button>
							</div>
						</React.Fragment>
					)}
					{!isApplicantListPage && isDetailsPage && (
						<div className="main-navigation__button-publicar">
							<Button contained onClick={openToolDrawer}>
								Herramientas
							</Button>
						</div>
					)}

					<div className="main-navigation__button">{element}</div>

					<ClickAwayListener onClickAway={closeMenuHandler}>
						<div className="main-navigation__button-profile">
							<Button contained onClick={openMenuHandler}>
								<div className="profile__nav-button">
									<p>{auth.name}</p>
									<div>
										<img src={DropIcon} alt="" />
									</div>
								</div>
								{isMenuOpen && <Menu />}
							</Button>
						</div>
					</ClickAwayListener>
				</div>
			</MainHeader>
		</React.Fragment>
	);
};

export default MainNavigation;
