import React, { useState, useEffect, useContext } from 'react';

import { useHistory, useParams, useLocation } from 'react-router-dom';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import MainNavigation from '../../shared/components/Navigation/MainNavigation';
import Card from '../../shared/components/UIElements/card';
import ScrollToTop from '../../shared/utils/scrollToTop';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import GoBack from '../../shared/components/Utils/goBack';

import './applicantsList.css';

const ApplicantsList = () => {
	const auth = useContext(AuthContext);

	const [applicants, setApplicants] = useState([1, 2, 3, 4, 5]);
	const [offerId, setOfferId] = useState(undefined);
	const [isLoaded, setIsLoaded] = useState(false);

	// eslint-disable-next-line
	const { isLoading, error, sendRequest, clearError } = useHttpClient();

	const location = useLocation();
	const history = useHistory();
	const params = useParams();

	useEffect(() => {
		const getApplicants = async (id) => {
			try {
				const response = await sendRequest(
					process.env.REACT_APP_BACKEND_URL + `/applicant/${id}/list`,
					'GET',
					null,
					{
						Authorization: `Bearer ${auth.token}`,
						'Content-type': 'application/json',
					}
				);
				setApplicants(response.applicants);
				setOfferId(id);
			} catch (err) {}
			setIsLoaded(true);
		};
		if (params) {
			const offerId = params.id;
			getApplicants(offerId);
		} else {
			history.push('/offer');
		}
	}, [location, sendRequest, auth.token, history, params]);

	return (
		<React.Fragment>
			<ScrollToTop />
			<MainNavigation />
			<main className="applicantsList_page">
				<div className="applicantsList__container">
					<div className="applicantsList__header">
						<div className="applicantsList__header-btn">
							<GoBack />
						</div>
						<h2>Lista de Postulantes</h2>
					</div>
					<div className="applicant__grid-template">
						{isLoaded ? (
							<React.Fragment>
								{applicants.length !== 0 ? (
									<React.Fragment>
										{applicants.map((user) => (
											<Card
												href={`/offer/${offerId}/applicants/${user.id}`}
												key={user.id}
												name={user.name}
												profesion={user.profesion}
												job={user.job}
												rent={user.rent}
												location={user.region}
												antiquity={user.antiquity}
											/>
										))}
									</React.Fragment>
								) : (
									<div className="no-offers_container">
										<h1>(⩾﹏⩽)</h1>
										<p>Parece que nadie ha postulado aún</p>
									</div>
								)}
							</React.Fragment>
						) : (
							<LoadingSpinner contained />
						)}
					</div>
				</div>
			</main>
		</React.Fragment>
	);
};

export default ApplicantsList;
