import { useState, useCallback, useEffect, useContext } from 'react';

import { AuthContext } from '../context/auth-context';
import { useHttpClient } from './http-hook';

export const useOptions = () => {
	const auth = useContext(AuthContext);

	const [professions, setProfessions] = useState([]);
	const [jobs, setJobs] = useState([]);
	// eslint-disable-next-line
	const { isLoading, error, sendRequest, clearError } = useHttpClient();

	const getProfessions = useCallback(async () => {
		try {
			const response = await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/profession`,
				'GET',
				null,
				{
					Authorization: `Bearer ${auth.token}`,
				}
			);
			setProfessions(response.professions);
		} catch (err) {
			setProfessions([{ name: 'Por favor, recargar' }]);
		}
	}, [sendRequest, auth.token]);

	const getJobs = useCallback(async () => {
		try {
			const response = await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/job`,
				'GET',
				null,
				{
					Authorization: `Bearer ${auth.token}`,
				}
			);
			setJobs(response.jobs);
		} catch (err) {
			setJobs([{ name: 'Por favor, recargar' }]);
		}
	}, [sendRequest, auth.token]);

	useEffect(() => {
		getProfessions();
		getJobs();
	}, [getProfessions, getJobs]);

	return { professions, jobs };
};
