import React, { useState, useEffect, useContext } from 'react';

import { parse, stringify } from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { useOptions } from '../../shared/hooks/options-hook';

import MainNavigation from '../../shared/components/Navigation/MainNavigation';
import InfiniteScroll from 'react-infinite-scroll-component';
import Card from '../../shared/components/UIElements/card';
import QuicKFilter from '../../Filter/components/quickFilter';
import ScrollToTop from '../../shared/utils/scrollToTop';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';

import './offer.css';

const OffersPage = () => {
	const auth = useContext(AuthContext);

	const [offers, setOffers] = useState([]);
	const [filters, setFilters] = useState(false);
	const [lastOffer, setLastOffer] = useState(undefined);

	const [isLoaded, setIsLoaded] = useState(false);
	const [isLast, setIsLast] = useState(true);
	// eslint-disable-next-line
	const { isLoading, error, sendRequest, clearError } = useHttpClient();
	const { professions, jobs } = useOptions();

	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		const getOffers = async (filters = {}) => {
			try {
				const response = await sendRequest(
					process.env.REACT_APP_BACKEND_URL + '/offer',
					'POST',
					{
						filters,
					},
					{
						Authorization: `Bearer ${auth.token}`,
						'Content-type': 'application/json',
					}
				);
				const offers = response.offers;
				if (offers.length < 20) {
					setIsLast(true);
				}
				setFilters(filters);
				setOffers(offers);
				setIsLoaded(true);
			} catch (err) {}
		};
		const queryParams = parse(location.search);
		getOffers(queryParams);
	}, [location, sendRequest, auth.token]);

	useEffect(() => {
		if (offers.length > 0) {
			setLastOffer(offers[offers.length - 1].created);
		}
	}, [offers]);

	const fetchOffers = async () => {
		try {
			const response = await sendRequest(
				process.env.REACT_APP_BACKEND_URL + '/offer',
				'POST',
				{
					lastOffer,
					filters,
				},
				{
					Authorization: `Bearer ${auth.token}`,
					'Content-type': 'application/json',
				}
			);
			const offers = response.offers;
			if (offers.length < 20) {
				setIsLast(false);
			}
			setOffers((prev) => [...prev, ...response.offers]);
		} catch (err) {}
	};

	const selectedProfession = (profession) => {
		const queryString = stringify({ profession: profession });
		history.push('/offer?' + queryString);
	};
	const selectedJob = (job) => {
		const queryString = stringify({ job: job });
		history.push('/offer?' + queryString);
	};

	return (
		<React.Fragment>
			<ScrollToTop />
			<MainNavigation />
			<main className="offer__page">
				<div className="ofertas__filters">
					<QuicKFilter
						isFilter
						title="Profesiones"
						options={professions}
						onClick={selectedProfession}
					/>
					<QuicKFilter
						isFilter
						title="Oficios"
						options={jobs}
						onClick={selectedJob}
					/>
				</div>
				<div className="offer__container">
					<div className="offer__header">
						<h2>Ofertas activas</h2>
					</div>
					{isLoaded ? (
						<React.Fragment>
							{offers.length !== 0 ? (
								<InfiniteScroll
									className="offer_grid-template"
									dataLength={offers.length}
									next={fetchOffers}
									hasMore={isLast}
									loader={<h4>Loading...</h4>}
									endMessage={
										<div className="offer__end-infite-scroll">
											<p>
												<b>
													No se encontraron más ofertas activas
												</b>
											</p>
										</div>
									}
								>
									{offers.map((offer) => (
										<Card
											offer
											key={offer.id}
											title={offer.title}
											description={offer.description}
											href={`/offer/${offer.id}`}
											quantity={offer.quantity}
											applicants={offer.applicants.length}
											views={offer.views.length}
										/>
									))}
								</InfiniteScroll>
							) : (
								<div className="no-offers_container">
									<h1>(⩾﹏⩽)</h1>
									<p>Parece que no hay ofertas disponibles</p>
								</div>
							)}
						</React.Fragment>
					) : (
						<LoadingSpinner contained />
					)}
				</div>
			</main>
		</React.Fragment>
	);
};

export default OffersPage;
