export const validateInfo = (fetchedUserInfo, actualUserInfo) => {
	let isChanged = true;
	for (const key in fetchedUserInfo) {
		isChanged = isChanged && fetchedUserInfo[key].toString() === actualUserInfo[key].toString();
	}
	return isChanged;
};

export const getChangedInfo = (fetchedUserInfo, actualUserInfo) => {
	const changedinfo = {};
	for (const key in fetchedUserInfo) {
		if (fetchedUserInfo[key].toString() !== actualUserInfo[key].toString()) {
			changedinfo[key] = actualUserInfo[key];
		}
	}
	return changedinfo;
};
