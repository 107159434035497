import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-alert';
import AlertMUITemplate from 'react-alert-template-mui';

import App from './App';

import './index.css';

const options = {
	timeout: 5000,
};

const app = (
	<Provider template={AlertMUITemplate} {...options}>
		<App />
	</Provider>
);

ReactDOM.render(app, document.getElementById('root'));