import React, { useState, useEffect, useContext } from 'react';

import { useHistory, useParams, useLocation } from 'react-router-dom';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import MainNavigation from '../../shared/components/Navigation/MainNavigation';
import ScrollToTop from '../../shared/utils/scrollToTop';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import GoBack from '../../shared/components/Utils/goBack';

import './applicantsProfile.css';
import Section from '../../shared/components/Utils/Section';

const ApplicantsProfile = (props) => {
	const auth = useContext(AuthContext);

	const [isLoaded, setIsLoaded] = useState(false);
	const [applicantData, setApplicantData] = useState(false);

	// eslint-disable-next-line
	const { isLoading, error, sendRequest, clearError } = useHttpClient();

	const location = useLocation();
	const history = useHistory();
	const params = useParams();

	useEffect(() => {
		const getApplicants = async (id) => {
			try {
				const response = await sendRequest(
					process.env.REACT_APP_BACKEND_URL + `/applicant/${id}/data`,
					'GET',
					null,
					{
						Authorization: `Bearer ${auth.token}`,
						'Content-type': 'application/json',
					}
				);
				setApplicantData(response.user);
			} catch (err) {}
			setIsLoaded(true);
		};
		if (params) {
			const userId = params.userId;
			getApplicants(userId);
		} else {
			history.push('/offer');
		}
	}, [location, sendRequest, auth.token, history, params]);

	return (
		<React.Fragment>
			<ScrollToTop />
			<MainNavigation />
			<main className="profile_page">
				<GoBack />
				{isLoaded ? (
					<React.Fragment>
						{applicantData ? (
							<React.Fragment>
								<div className="profile__container">
									<Section
										first
										title="Datos personales"
										items={[
											{
												title: 'Nombre Completo',
												text: `${applicantData.name} ${applicantData.lastname}`,
											},
										]}
									/>
									<Section
										title="Datos de contacto"
										items={[
											{
												title: 'Teléfono',
												text: applicantData.phone,
											},
											{
												title: 'Correo electrónico',
												text: applicantData.email,
											},
										]}
									/>
									<Section
										title="Información laboral"
										items={[
											{
												title: 'Disponibilidad',
												text: applicantData.availableStatus,
											},
											{
												title: 'Tipo de empleo',
												text: `${applicantData.workType.join(
													' '
												)}`,
											},
											{
												title: 'Estatus actual',
												text: `${
													applicantData.workingStatus
														? 'Me encontró trabajando'
														: 'Estoy desempleado'
												}`,
											},
										]}
									/>
									<Section
										title="Datos académicos"
										items={[
											{
												title: 'Nivel de estudio',
												text: `${applicantData.grade}${
													!!applicantData.level
														? `, ${applicantData.level}`
														: ''
												}`,
											},
											{
												title: 'Certificados',
												text: applicantData.certificates,
											},
											{
												title: 'Idiomas',
												text: applicantData.languages,
											},
										]}
									/>
									<Section
										title="Profesiones"
										items={applicantData.profession.map(
											(profession) => {
												return {
													title: profession.profession.name,
													text: profession.details,
												};
											}
										)}
									/>
									<Section
										title="Oficios"
										items={applicantData.job.map((job) => {
											return {
												title: job.job.name,
												text: job.details || 'Sin descripción',
											};
										})}
									/>
								</div>
							</React.Fragment>
						) : (
							<div className="lost-page">
								<h1>(⩾﹏⩽)</h1>
								<h3>Ups!</h3>
								<p>La página que estás buscando no está disponible</p>
							</div>
						)}
					</React.Fragment>
				) : (
					<LoadingSpinner contained />
				)}
			</main>
		</React.Fragment>
	);
};

export default ApplicantsProfile;
