import React from 'react';

import { useForm } from '../../../shared/hooks/form-hook';
import { VALIDATOR_IS_NUMBER, VALIDATOR_REQUIRE } from '../../../shared/utils/validators';

import Button from '../../../shared/components/FormElements/button';
import Input from '../../../shared/components/FormElements/input';

import './form.css';

const GeneralForm = (props) => {
	const inputs = {
		title: {
			value: '',
			isValid: false,
		},
		heading: {
			value: '',
			isValid: false,
		},
		description: {
			value: '',
			isValid: false,
		},
		quantity: {
			value: '',
			isValid: false,
		},
	};
	const [formState, inputHandler] = useForm(inputs, false);

	const submitHandler = (event) => {
		event.preventDefault();
		const formData = {
			title: formState.inputs.title.value,
			heading: formState.inputs.heading.value,
			description: formState.inputs.description.value,
			quantity: formState.inputs.quantity.value,
		};
		props.nextStep(formData);
	};

	return (
		<div className="form__container">
			<div>
				<form onSubmit={submitHandler}>
					<div className="form__inputs">
						<Input
							id="title"
							label="Título: *"
							type="text"
							onInput={inputHandler}
							validators={[VALIDATOR_REQUIRE()]}
						/>
						<Input
							id="heading"
							label="Rubro: *"
							type="text"
							onInput={inputHandler}
							validators={[VALIDATOR_REQUIRE()]}
						/>
						<Input
							id="description"
							label="Descripción: *"
							type="textarea"
							onInput={inputHandler}
							validators={[VALIDATOR_REQUIRE()]}
						/>
						<Input
							id="quantity"
							label="Puestos disponibles: *"
							type="text"
							onInput={inputHandler}
							validators={[VALIDATOR_REQUIRE(), VALIDATOR_IS_NUMBER()]}
						/>
					</div>
					<div>
						<Button contained type="submit" disabled={!formState.isValid}>
							Siguiente
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default GeneralForm;
