import React from 'react';
import HttpsRedirect from 'react-https-redirect';

import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { AuthContext } from './shared/context/auth-context';
import { useAuth } from './shared/hooks/auth-hook';

// rutas sin autenticacion
import LoginPage from './Auth/login';

//rutas autenticadas
import ResumenPage from './Resume/pages/resume';
import OfferPage from './Offer/pages/offer';
import OfferDetailPage from './Offer/pages/offerDetails';
import ApplicantListPage from './Applicants/pages/applicantsList';
import ApplicantProfilePage from './Applicants/pages/applicantsProfile';

import './index.css';

const App = () => {
	const { token, id, name, login, logout } = useAuth();

	let routes = (
		<Switch>
			<Route path="/login" component={LoginPage} />
			<Redirect to="/login" />
		</Switch>
	);

	if (!!token) {
		routes = (
			<Switch>
				<Route path="/resume" component={ResumenPage} />
				<Route
					path="/offer/:id/applicants/:userId"
					component={ApplicantProfilePage}
				/>
				<Route path="/offer/:id/applicants" component={ApplicantListPage} />
				<Route exact path="/offer/:id" component={OfferDetailPage} />
				<Route exact path="/offer" component={OfferPage} />
				<Redirect to="/offer" />
			</Switch>
		);
	}

	return (
		<HttpsRedirect>
			<AuthContext.Provider
				value={{
					isLoggedIn: !!token,
					token: token,
					id: id,
					name: name,
					login: login,
					logout: logout,
				}}
			>
				<Router>{routes}</Router>
			</AuthContext.Provider>
		</HttpsRedirect>
	);
};

export default App;
