import React from 'react';
import { useHistory } from 'react-router-dom';

import ArrowBack from '../../../assets/icons/arrow_back.svg';
import './goBack.css';

const GoBack = () => {
	const history = useHistory();
	const goBackHandler = () => {
		history.goBack();
	};
	return (
		<div className="goBack__container">
			<button className="goBack__btn" onClick={goBackHandler}>
				<div className="goBack__btn-content">
					<img src={ArrowBack} alt="" />
					<p>Volver</p>
				</div>
			</button>
		</div>
	);
};

export default GoBack;
