import React from 'react';
import Output from '../UIElements/output';

import './Section.css';

const Section = (props) => {
	return (
		<div className="section__container">
			<div className="section__header">
				<h3>{props.title}</h3>
			</div>
			<div>
				{props.items.map((item, index) => (
					<Output
						key={`${item.title}-${item.text}-${index}`}
						index={index}
						title={item.title}
						text={item.text}
						last={props.items.length === index + 1}
					/>
				))}
			</div>
		</div>
	);
};

export default Section;
