import React from 'react';

import { stringify } from 'query-string';
import { useHistory } from 'react-router-dom';

import { useForm } from '../../shared/hooks/form-hook';
import { useOptions } from '../../shared/hooks/options-hook';

import Button from '../../shared/components/FormElements/button';
import InputSelect from '../../shared/components/FormElements/inputSelect';

import { regiones } from '../../assets/data/regionOptions';

import './filterForm.css';

const ResumeFilterForm = (props) => {
	const { professions, jobs } = useOptions();

	const history = useHistory();

	const inputs = {
		region: {
			value: '',
			isValid: true,
		},
		oficio: {
			value: '',
			isValid: true,
		},
		profesion: {
			value: '',
			isValid: true,
		},
	};

	const [formState, inputHandler] = useForm(inputs, false);

	const filterHandler = (event) => {
		event.preventDefault();
		const queryParams = {};
		queryParams['region'] =
			formState.inputs.region.value !== ''
				? formState.inputs.region.value
				: undefined;
		if (formState.inputs.oficio.value !== '') {
			const job = jobs.find((job) => job.name === formState.inputs.oficio.value);
			queryParams['job'] = job._id;
		}
		if (formState.inputs.profesion.value !== '') {
			const profession = professions.find(
				(profession) => profession.name === formState.inputs.profesion.value
			);
			queryParams['profession'] = profession._id;
		}

		const queryString = stringify(queryParams);
		props.closeSideDrawer();
		history.push('/resume?' + queryString);
	};

	const clearFilterHandler = (event) => {
		event.preventDefault();
		props.closeSideDrawer();
		history.push('/resume?');
	};

	return (
		<form className="filter">
			<InputSelect
				id="region"
				label="Región: *"
				options={regiones}
				onInput={inputHandler}
				validators={[]}
				initialValidity={true}
				placeholder="Selecciona una región..."
			/>
			<InputSelect
				id="oficio"
				label="Oficio: *"
				disabled={formState.inputs.profesion.value}
				options={jobs}
				onInput={inputHandler}
				validators={[]}
				initialValidity={true}
				placeholder="Selecciona un oficio..."
			/>
			<InputSelect
				id="profesion"
				label="Profesión: *"
				disabled={formState.inputs.oficio.value}
				options={professions}
				onInput={inputHandler}
				validators={[]}
				initialValidity={true}
				placeholder="Selecciona una profesión..."
			/>
			<p className="filter__helper-text">Debes seleccionar al menos un filtro</p>
			<Button contained onClick={filterHandler} disabled={!formState.isValid}>
				Filtrar
			</Button>
			<hr />
			<Button outlined onClick={clearFilterHandler}>
				Limpiar filtros
			</Button>
		</form>
	);
};

export default ResumeFilterForm;
