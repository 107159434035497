import React from 'react';

import { Link } from 'react-router-dom';

import applicantIcon from '../../../assets/icons/applicant.svg';
import quantityIcon from '../../../assets/icons/quantity.svg';
import EyeIcon from '../../../assets/icons/eye-black.svg';

import './card.css';

const Card = (props) => {
	let element;

	if (props.offer) {
		element = (
			<Link to={props.href} className="card__link">
				<div className="card-container">
					<div className="card-container__text">
						<h6>{props.title}</h6>
						<p>{props.description}</p>
					</div>
					<div className="card-container__info-details">
						<div className="card-container__info_item">
							<p>{props.quantity}</p>
							<img src={quantityIcon} alt="" />
						</div>
						<div className="card-container__info_item">
							<p>{props.applicants}</p>
							<img src={applicantIcon} alt="" />
						</div>
						<div className="card-container__info_item">
							<p>{props.views}</p>
							<img src={EyeIcon} alt="" />
						</div>
					</div>
				</div>
			</Link>
		);
	} else {
		element = (
			<Link to={props.href} className="card__link">
				<div className="card-element-list">
					<div className="card-element-list__text-name">
						<h6>{props.name}</h6>
					</div>
					<div className="card-element-list__tags">
						{props.job !== undefined && (
							<div
								className={`tag-detail ${
									!props.job && 'tag-detail__false'
								}`}
							>
								<p>Profesión</p>
							</div>
						)}
						{props.profesion !== undefined && (
							<div
								className={`tag-detail ${
									!props.profesion && 'tag-detail__false'
								}`}
							>
								<p>Oficio</p>
							</div>
						)}

						<div
							className={`tag-detail ${!props.rent && 'tag-detail__false'}`}
						>
							<p>Renta</p>
						</div>
						<div
							className={`tag-detail ${
								!props.location && 'tag-detail__false'
							}`}
						>
							<p>Ubicación</p>
						</div>
						{props.antiquity !== undefined && (
							<div
								className={`tag-detail ${
									!props.antiquity && 'tag-detail__false'
								}`}
							>
								<p>Experiencia</p>
							</div>
						)}
					</div>
				</div>
			</Link>
		);
	}

	return <React.Fragment>{element}</React.Fragment>;
};

export default Card;
