import React, { useState, useEffect, useContext } from 'react';

import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import MainNavigation from '../../shared/components/Navigation/MainNavigation';
import ScrollToTop from '../../shared/utils/scrollToTop';
import GeneralInfo from '../components/generalInfo';
import RentInfo from '../components/rentInfo';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';

import './resume.css';

const Resume = () => {
	const auth = useContext(AuthContext);

	const [resume, setResume] = useState(false);

	// eslint-disable-next-line
	const { isLoading, error, sendRequest, clearError } = useHttpClient();

	const location = useLocation();

	useEffect(() => {
		const getResumeInfo = async (filters = {}) => {
			try {
				const response = await sendRequest(
					process.env.REACT_APP_BACKEND_URL + '/resume',
					'POST',
					{
						filters,
					},
					{
						Authorization: `Bearer ${auth.token}`,
						'Content-type': 'application/json',
					}
				);
				setResume(response.resume);
			} catch (err) {}
		};
		const queryParams = parse(location.search);
		getResumeInfo(queryParams);
	}, [location, sendRequest, auth.token]);

	return (
		<React.Fragment>
			<ScrollToTop />
			<MainNavigation />
			<main className="resume_page">
				<div className="resume__container">
					<div className="resume__title">
						<h2>Resumen general</h2>
					</div>
					{isLoading ? (
						<LoadingSpinner contained />
					) : (
						<React.Fragment>
							<div className="resume__body">
								<GeneralInfo
									users={resume.users}
									offers={resume.offers}
									applicants={resume.applies}
									job={resume.mostCommonJob}
									profession={resume.mostCommonProfession}
									specific={resume.specific}
								/>
								<RentInfo
									averageRentAwait={resume.averageApplyRent}
									averageRentOffered={resume.averageOfferRent}
								/>
							</div>
						</React.Fragment>
					)}
				</div>
			</main>
		</React.Fragment>
	);
};

export default Resume;
