import React, { useEffect, useState } from 'react';

import { useOptions } from '../../../shared/hooks/options-hook';
import { withStyles } from '@material-ui/core/styles';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Button from '../../../shared/components/FormElements/button';
import InputSelect from '../../../shared/components/FormElements/inputSelect';

import { antiquities } from '../../../assets/data/antiquityOptions';

import './form.css';

const RedRadio = withStyles({
	root: {
		color: '#D3131C',
		'&$checked': {
			color: '#D3131C',
		},
	},
	checked: {},
})((props) => <Radio color="default" {...props} />);

const ProfessionalForm = (props) => {
	const [jobOption, setJobOption] = useState('noExperience');

	const [selectedProfession, setSelectedProfession] = useState(false);
	const [selectedJob, setSelectedJob] = useState(false);
	const [antiquity, setAntiquity] = useState(false);

	const [isFormValid, setIsFormValid] = useState(false);

	const { professions, jobs } = useOptions();

	useEffect(() => {
		let isValid = true;
		if (jobOption === 'job') {
			isValid = isValid && selectedJob;
			isValid = isValid && antiquity;
			setSelectedProfession('');
		} else if (jobOption === 'profession') {
			isValid = isValid && selectedProfession;
			isValid = isValid && antiquity;
			setSelectedJob('');
		} else if (jobOption === 'noExperience') {
			isValid = isValid && true;
			setSelectedProfession('');
			setSelectedJob('');
			setAntiquity('');
		}
		setIsFormValid(isValid);
	}, [jobOption, antiquity, selectedJob, selectedProfession]);

	const submitHandler = (event) => {
		event.preventDefault();
		const formData = {};

		if (jobOption === 'job') {
			const job = jobs.find((job) => job.name === selectedJob);
			formData['job'] = job._id;
			formData['antiquity'] = antiquity;
		}
		if (jobOption === 'profession') {
			const profession = professions.find(
				(profession) => profession.name === selectedJob
			);
			formData['profession'] = profession;
			formData['antiquity'] = antiquity;
		}
		formData['experience'] = jobOption === 'noExperience' ? false : true;

		props.nextStep(formData);
	};

	const radioButtonHandler = (event) => {
		setJobOption(event.target.value);
	};
	const professionHandler = (event) => {
		setSelectedProfession(event.target.value);
	};
	const jobHandler = (event) => {
		setSelectedJob(event.target.value);
	};
	const antiquityHandler = (event) => {
		setAntiquity(event.target.value);
	};

	return (
		<div className="form__container">
			<div>
				<form onSubmit={submitHandler}>
					<div className="form__inputs">
						<RadioGroup
							row
							aria-label="profesion vs oficio"
							name="profesion vs ofico"
							value={jobOption}
							onChange={radioButtonHandler}
						>
							<FormControlLabel
								value="profession"
								control={<RedRadio />}
								label="Profesión"
							/>
							<FormControlLabel
								value="job"
								control={<RedRadio />}
								label="Oficio"
							/>
							<FormControlLabel
								value="noExperience"
								control={<RedRadio />}
								label="No requerido"
							/>
						</RadioGroup>
						<InputSelect
							id="profession"
							label="Profesión: *"
							disabled={jobOption !== 'profession' && true}
							outsource
							changeHandler={professionHandler}
							value={selectedProfession}
							options={professions}
							placeholder="Selecciona una profesión..."
							validators={[]}
						/>
						<InputSelect
							id="job"
							label="Oficio: *"
							disabled={jobOption !== 'job' && true}
							outsource
							changeHandler={jobHandler}
							value={selectedJob}
							options={jobs}
							placeholder="Selecciona un oficio..."
							validators={[]}
						/>
						<InputSelect
							id="antiquity"
							label="Antiguedad: *"
							disabled={jobOption === 'noExperience' && true}
							outsource
							options={antiquities}
							value={antiquity}
							changeHandler={antiquityHandler}
							placeholder="Antiguedad..."
						/>
					</div>
					<div>
						<Button contained type="submit" disabled={!isFormValid}>
							Siguiente
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ProfessionalForm;
