import React, { useEffect, useState } from 'react';

import { useForm } from '../../../shared/hooks/form-hook';
import { VALIDATOR_REQUIRE } from '../../../shared/utils/validators';

import Button from '../../../shared/components/FormElements/button';
import InputSelect from '../../../shared/components/FormElements/inputSelect';

import { regiones } from '../../../assets/data/regionOptions';
import { aggrements } from '../../../assets/data/aggrementOptions';
import { jornadas } from '../../../assets/data/jornadaOptions';
import { rentas } from '../../../assets/data/rentOptions';

import './form.css';

const DetailsForm = (props) => {
	const [comunas, setComunas] = useState([]);

	const inputs = {
		region: {
			value: '',
			isValid: false,
		},
		comuna: {
			value: '',
			isValid: false,
		},
		workDay: {
			value: '',
			isValid: false,
		},
		aggrementType: {
			value: '',
			isValid: false,
		},
		rent: {
			value: '',
			isValid: false,
		},
	};
	const [formState, inputHandler] = useForm(inputs, false);

	useEffect(() => {
		let newComunas = regiones.find(
			(region) => region.name === formState.inputs.region.value
		);

		if (!newComunas) {
			setComunas([]);
		} else {
			setComunas(newComunas.comunas);
		}
	}, [formState.inputs.region.value]);

	const submitHandler = (event) => {
		event.preventDefault();
		const formData = {
			region: formState.inputs.region.value,
			comuna: formState.inputs.comuna.value,
			workday: formState.inputs.workDay.value,
			aggrementType: formState.inputs.aggrementType.value,
			rent: formState.inputs.rent.value,
		};
		props.nextStep(formData);
	};

	return (
		<div className="form__container">
			<div>
				<form onSubmit={submitHandler}>
					<div className="form__inputs">
						<InputSelect
							id="region"
							label="Región: *"
							onInput={inputHandler}
							options={regiones}
							placeholder="Selecciona una región..."
							validators={[VALIDATOR_REQUIRE()]}
						/>
						<InputSelect
							id="comuna"
							label="Comuna: *"
							onInput={inputHandler}
							options={comunas}
							placeholder="Selecciona una comuna..."
							validators={[VALIDATOR_REQUIRE()]}
						/>
						<InputSelect
							id="workDay"
							label="Jornada: *"
							onInput={inputHandler}
							options={jornadas}
							placeholder="Selecciona una jornada..."
							validators={[VALIDATOR_REQUIRE()]}
						/>
						<InputSelect
							id="aggrementType"
							label="Contrato: *"
							onInput={inputHandler}
							options={aggrements}
							placeholder="Selecciona un contrato..."
							validators={[VALIDATOR_REQUIRE()]}
						/>
						<InputSelect
							id="rent"
							label="Renta: *"
							onInput={inputHandler}
							options={rentas}
							placeholder="Selecciona una renta..."
							validators={[VALIDATOR_REQUIRE()]}
						/>
					</div>
					<div>
						<Button contained type="submit" disabled={!formState.isValid}>
							Siguiente
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default DetailsForm;
