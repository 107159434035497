import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Button as Btn } from '@material-ui/core';

const ContainedButton = withStyles((theme) => ({
	root: {
		color: 'white',
		backgroundColor: '#D3131C',
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'Rajdhani',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
		].join(','),
		fontSize: 14,
		textTransform: 'none',
		borderWidth: 2,
		fontWeight: '400',
		padding: '0.4rem 0',
		borderColor: '#D3131C',
		'&:hover': {
			backgroundColor: '#D3131C',
			borderColor: '#D3131C',
		},
		'&:disabled': {
			color: 'white',
			borderColor: '#B4B4B4',
			backgroundColor: '#B4B4B4',
		},
	},
}))(Btn);

const OutlinedButton = withStyles((theme) => ({
	root: {
		color: '#D3131C',
		borderColor: '#D3131C',
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'Rajdhani',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
		].join(','),
		textTransform: 'none',
		borderWidth: 2,
		fontSize: 14,
		fontWeight: '300',
		padding: '0.3rem 0',
		boxSizing: 'border-box',
		backgroundColor: 'white',
		'&:hover': {
			borderWidth: 2,
			borderColor: '#D3131C',
			color: '#D3131C',
			backgroundColor: 'white',
		},
		'&:disabled': {
			color: '#B4B4B4',
			borderWidth: 2,
			backgroundColor: '#F1F1F1',
			borderColor: '#B4B4B4',
		},
	},
}))(Btn);

const OutlinedTransparentButton = withStyles((theme) => ({
	root: {
		color: '#D3131C',
		borderColor: '#D3131C',
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'Rajdhani',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
		].join(','),
		textTransform: 'none',
		borderWidth: 2,
		fontSize: 14,
		fontWeight: '300',
		padding: '0.3rem 0',
		boxSizing: 'border-box',
		backgroundColor: 'transparent',
		'&:hover': {
			borderWidth: 2,
			borderColor: '#D3131C',
			color: '#D3131C',
			backgroundColor: 'transparent',
		},
		'&:disabled': {
			color: '#B4B4B4',
			borderWidth: 2,
			backgroundColor: 'transparent',
			borderColor: '#B4B4B4',
		},
	},
}))(Btn);
const ElevatedButton = withStyles((theme) => ({
	root: {
		color: 'white',
		backgroundColor: '#D3131C',
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'Rajdhani',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
		].join(','),
		fontSize: 14,
		textTransform: 'none',
		borderWidth: 2,
		fontWeight: '500',
		padding: '0.6rem 0',
		borderColor: '#D3131C',
		boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.55)',
		'&:hover': {
			backgroundColor: '#D3131C',
			borderColor: '#D3131C',
		},
		'&:disabled': {
			color: 'white',
			borderColor: '#B4B4B4',
			backgroundColor: '#B4B4B4',
		},
	},
}))(Btn);
const OutlinedElevatedButton = withStyles((theme) => ({
	root: {
		color: '#D3131C',
		borderColor: '#D3131C',
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'Rajdhani',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
		].join(','),
		textTransform: 'none',
		borderWidth: 2,
		fontSize: 14,
		fontWeight: '500',
		padding: '0.6rem 0',
		boxSizing: 'border-box',
		backgroundColor: 'white',
		boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.55)',
		'&:hover': {
			borderWidth: 2,
			borderColor: '#D3131C',
			color: '#D3131C',
			backgroundColor: 'white',
		},
		'&:disabled': {
			color: '#B4B4B4',
			borderWidth: 2,
			backgroundColor: '#F1F1F1',
			borderColor: '#B4B4B4',
		},
	},
}))(Btn);

const Button = (props) => {
	if (props.contained) {
		return (
			<ContainedButton
				fullWidth
				disabled={props.disabled}
				type={props.type}
				onClick={props.type === 'submit' ? null : props.onClick}
				color="primary"
				href={props.href}
				download={props.download}
			>
				{props.children}
			</ContainedButton>
		);
	} else if (props.outlined) {
		return (
			<OutlinedButton
				fullWidth
				disabled={props.disabled}
				type={props.type}
				onClick={props.type === 'submit' ? null : props.onClick}
				variant="outlined"
				color="primary"
				href={props.href}
				download={props.download}
				disableElevation={props.elevated}
			>
				{props.children}
			</OutlinedButton>
		);
	} else if (props.transparent) {
		return (
			<OutlinedTransparentButton
				fullWidth
				disabled={props.disabled}
				type={props.type}
				onClick={props.type === 'submit' ? null : props.onClick}
				variant="outlined"
				color="primary"
				href={props.href}
				download={props.download}
				disableElevation={props.elevated}
			>
				{props.children}
			</OutlinedTransparentButton>
		);
	} else if (props.elevated) {
		return (
			<ElevatedButton
				fullWidth
				disabled={props.disabled}
				type={props.type}
				onClick={props.type === 'submit' ? null : props.onClick}
				variant="contained"
				color="primary"
				href={props.href}
			>
				{props.children}
			</ElevatedButton>
		);
	} else if (props.outlinedElevated) {
		return (
			<OutlinedElevatedButton
				fullWidth
				disabled={props.disabled}
				type={props.type}
				onClick={props.type === 'submit' ? null : props.onClick}
				variant="contained"
				color="primary"
				href={props.href}
			>
				{props.children}
			</OutlinedElevatedButton>
		);
	}
};

export default Button;
